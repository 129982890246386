import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'
import { CHAIN_ID } from './networks'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
 
  {
    pid: 0,
    v1pid: 100,
    lpSymbol: 'APT-BNB LP',
    lpAddresses: {
      97: '0xC6F3fDC20068e9367E9D4a15cc7F21933BFf393A',
      56: '0x7db1116c489215E363108e65d45966A2784D1162',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 1,
    v1pid: 100,
    lpSymbol: 'APT-BUSD LP',
    lpAddresses: {
      97: '0xC6F3fDC20068e9367E9D4a15cc7F21933BFf393A',
      56: '0xc17816Dbe678Ea9787024e638A9D920f59e5E66C',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.busd,
  }
].filter((f) => !!f.lpAddresses[CHAIN_ID])

export default farms
