import BigNumber from 'bignumber.js'
import { BLOCKS_PER_YEAR } from 'config'
import lpAprs from 'config/constants/lpAprs.json'

/**
 * Get the APR value in %
 * @param stakingTokenPrice Token price in the same quote currency
 * @param rewardTokenPrice Token price in the same quote currency
 * @param totalStaked Total amount of stakingToken in the pool
 * @param tokenPerBlock Amount of new cake allocated to the pool for each new block
 * @returns Null if the APR is NaN or infinite.
 */
export const getPoolApr = (
  stakingTokenPrice: number,
  rewardTokenPrice: number,
  totalStaked: number,
  tokenPerBlock: number,
): number => {
  //console.log.log("stakingTokenPrice : " + stakingTokenPrice) ;
  //console.log.log("rewardTokenPrice : " + rewardTokenPrice) ;
  //console.log.log("totalStaked : " + totalStaked) ;
  //console.log.log("tokenPerBlock : " + tokenPerBlock) ;
  const totalRewardPricePerYear = new BigNumber(rewardTokenPrice).times(tokenPerBlock).times(BLOCKS_PER_YEAR)
  console.debug("totalRewardPricePerYear : " + totalRewardPricePerYear);
  const totalStakingTokenInPool = new BigNumber(stakingTokenPrice).times(totalStaked)
  //console.log.log("totalStakingTokenInPool : " + totalStakingTokenInPool) ;

  const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100)
  //console.log.log("apr : " + apr) ;

  return apr.isNaN() || !apr.isFinite() ? null : apr.toNumber()
}

/**
 * Get farm APR value in %
 * @param poolWeight allocationPoint / totalAllocationPoint
 * @param cakePriceUsd Cake price in USD
 * @param poolLiquidityUsd Total pool liquidity in USD
 * @param farmAddress Farm Address
 * @returns Farm Apr
 */
export const getFarmApr = (
  poolWeight: BigNumber,
  cakePriceUsd: BigNumber,
  poolLiquidityUsd: BigNumber,
  farmAddress: string,
  regularCakePerBlock: number,
): { cakeRewardsApr: number; lpRewardsApr: number } => {
  console.debug("cakePriceUSd : " + cakePriceUsd + " poolWeight = " + poolWeight + " poolLiquidityUsd " + poolLiquidityUsd);
  console.debug("regularCakePerBlock " + regularCakePerBlock)
  const yearlyCakeRewardAllocation = poolWeight
    ? poolWeight.times(BLOCKS_PER_YEAR * regularCakePerBlock)
    : new BigNumber(NaN)
  console.debug("yearlyCakeRewardAllocation " + yearlyCakeRewardAllocation)
  const cakeRewardsApr = yearlyCakeRewardAllocation.times(cakePriceUsd).div(poolLiquidityUsd).times(100)
  let cakeRewardsAprAsNumber = null
  if (!cakeRewardsApr.isNaN() && cakeRewardsApr.isFinite()) {
    cakeRewardsAprAsNumber = cakeRewardsApr.toNumber()
  }
  const lpRewardsApr = lpAprs[farmAddress?.toLocaleLowerCase()] ?? 0
  return { cakeRewardsApr: cakeRewardsAprAsNumber, lpRewardsApr }
}

export default null
