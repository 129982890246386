import masterchefABI from 'config/abi/masterchef.json'
import chunk from 'lodash/chunk'
import { masterChefMultiCall, multicallv2 } from 'utils/multicall'
import { SerializedFarmConfig } from '../../config/constants/types'
import { SerializedFarm } from '../types'
import { getMasterChefAddress } from '../../utils/addressHelpers'
import { getMasterchefContract } from '../../utils/contractHelpers'

const masterChefAddress = getMasterChefAddress()
const masterChefContract = getMasterchefContract()

export const fetchMasterChefFarmPoolLength = async () => {
  const poolLength = await masterChefContract.poolLength()
  return poolLength
}

const masterChefFarmCalls = (farm: SerializedFarm) => {
  const { pid } = farm
  console.debug("masterChefFarmCalls for " + pid );
  return pid || pid === 0
    ? [
      {
        address: masterChefAddress,
        name: 'poolInfo',
        params: [pid],
      },
      {
        address: masterChefAddress,
        name: 'totalAllocPoint',
      },
    ]
    : [null, null]
}

export const fetchMasterChefData = async (farms: SerializedFarmConfig[]): Promise<any[]> => {
  console.debug("FetchMasterChef Data for " + JSON.stringify(farms));
  const masterChefCalls = farms.map((farm) => masterChefFarmCalls(farm))
  const chunkSize = masterChefCalls.flat().length / farms.length

  const masterChefAggregatedCalls = masterChefCalls
    .filter((masterChefCall) => masterChefCall[0] !== null && masterChefCall[1] !== null)
    .flat()

  const masterChefMultiCallResult = await masterChefMultiCall(masterchefABI, masterChefAggregatedCalls)
  console.debug("masterChefMultiCallResult is " + masterChefMultiCallResult);
  const masterChefChunkedResultRaw = chunk(masterChefMultiCallResult, chunkSize)
  let masterChefChunkedResultCounter = 0
  return masterChefCalls.map((masterChefCall) => {
    if (masterChefCall[0] === null && masterChefCall[1] === null) {
      return [null, null]
    }
    const data = masterChefChunkedResultRaw[masterChefChunkedResultCounter]
    masterChefChunkedResultCounter++
    console.debug("fetchMasterChefData end");
    return data
  })
}
