function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
export var baseColors = {
    failure: "#ED4B9E",
    primary: "rgb(255, 186, 0)",
    primaryBright: "rgb(255, 186, 0)",
    primaryDark: "#0098A1",
    secondary: "yellow",
    success: "#31D0AA",
    warning: "#FFB237"
};
export var additionalColors = {
    binance: "#F0B90B",
    overlay: "#452a7a",
    gold: "#FFC700",
    silver: "#B2B2B2",
    bronze: "#E7974D"
};
export var lightColors = _objectSpread({}, baseColors, additionalColors, {
    background: "rgb(244, 218, 133)",
    backgroundDisabled: "#E9EAEB",
    backgroundAlt: "rgb(218, 190, 57)",
    backgroundAlt2: "rgb(244, 218, 133)",
    cardBorder: "rgb(218, 190, 57)",
    primary: "rgb(81, 51, 27)",
    primaryBackground: "rgb(255, 186, 0)",
    primaryText: "rgb(81, 51, 27)",
    secondary: "rgb(81, 51, 27)",
    primaryBright: "rgb(81, 51, 27)",
    contrast: "#191326",
    dropdown: "rgb(244, 218, 133)",
    dropdownDeep: "rgb(244, 218, 133)",
    invertedContrast: "rgb(244, 218, 133)",
    input: "rgb(244, 218, 133)",
    input2: "#eeeaf4",
    inputSecondary: "rgb(244, 218, 133)",
    tertiary: "rgb(244, 218, 133)",
    text: "#280D5F",
    textDisabled: "rgb(87, 92, 94)",
    textSubtle: "rgb(81, 51, 27)",
    disabled: "#E9EAEB",
    gradients: {
        bubblegum: "linear-gradient(111.68deg, rgb(244, 218, 133) 22%, rgb(254, 218, 133) 100%)",
        inverseBubblegum: "linear-gradient(139.73deg, #F3EFFF 0%, #E5FDFF 100%)",
        cardHeader: "linear-gradient(111.68deg, rgb(244, 218, 133) 22%, rgb(254, 218, 133) 100%)",
        blue: "linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%)",
        violet: "linear-gradient(180deg, #E2C9FB 0%, #CDB8FA 100%)",
        violetAlt: "linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%)",
        gold: "linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)"
    }
});
export var darkColors = _objectSpread({}, baseColors, additionalColors, {
    primaryBackground: "rgb(255, 186, 0)",
    primaryText: "rgb(255, 186, 0)",
    secondary: "yellow",
    background: "#08060B",
    backgroundDisabled: "#3c3742",
    backgroundAlt: "#27262c",
    backgroundAlt2: "#3D2A54",
    cardBorder: "#383241",
    contrast: "#FFFFFF",
    dropdown: "#1E1D20",
    dropdownDeep: "#100C18",
    invertedContrast: "#191326",
    input: "#372F47",
    input2: "#372F47",
    inputSecondary: "#262130",
    primaryDark: "#0098A1",
    tertiary: "#353547",
    text: "#F4EEFF",
    textDisabled: "#666171",
    textSubtle: "#B8ADD2",
    disabled: "#524B63",
    gradients: {
        bubblegum: "linear-gradient(139.73deg,#7A4319 0%,#FF933B 100%)",
        inverseBubblegum: "linear-gradient(139.73deg, #3D2A54 0%, #313D5C 100%)",
        cardHeader: "linear-gradient(166.77deg, #3B4155 0%, #3A3045 100%)",
        blue: "linear-gradient(180deg, #00707F 0%, #19778C 100%)",
        violet: "linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%)",
        violetAlt: "linear-gradient(180deg, #434575 0%, #66578D 100%)",
        gold: "linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)"
    }
});
