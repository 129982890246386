import { CurrencyAmount, Percent, Price } from '@pancakeswap/sdk'


import getNodeUrl from 'utils/getRpcUrl';
import { parseEther, formatEther } from "ethers/lib/utils";
import tokens from 'config/constants/tokens';
import { getRouterV2Contract } from 'utils/contractHelpers'
import BigNumber from 'bignumber.js'
import { BIG_ONE } from './bigNumber';

/**
 * Helper to multiply a Price object by an arbitrary amount
 */
export const multiplyPriceByAmount = (price: Price, amount: number, significantDigits = 18) => {
  if (!price) {
    return 0
  }

  return parseFloat(price.toSignificant(significantDigits)) * amount
}

/**
 * Returns the percent difference between the mid price and the execution price, i.e. price impact.
 * @param midPrice mid price before the trade
 * @param inputAmount the input amount of the trade
 * @param outputAmount the output amount of the trade
 */
export function computePriceImpact(
  midPrice: Price,
  inputAmount: CurrencyAmount,
  outputAmount: CurrencyAmount,
): Percent {
  const exactQuote = midPrice.raw.multiply(inputAmount.raw)
  // calculate slippage := (exactQuote - outputAmount) / exactQuote
  const slippage = exactQuote.subtract(outputAmount.raw).divide(exactQuote)
  return new Percent(slippage.numerator, slippage.denominator)
}

export async function getTokenPrice(tokenToSellAddress) {

  if (tokens.busd.address.toLowerCase() === tokenToSellAddress.toLowerCase()) {
    return BIG_ONE.toNumber()
  } else if (tokens.wbnb.address.toLowerCase() === tokenToSellAddress.toLowerCase()) {
    return (await getBnbPrice()).toNumber();
  }
  try {
    const nodeUrl = getNodeUrl();
    console.debug("nodeUrl is " + nodeUrl);
    const BNBTokenAddress = tokens.wbnb.address //BNB
    const USDTokenAddress = tokens.busd.address //BUSD
    let tokenToSell = parseEther("1.0");
    const contract = getRouterV2Contract();
    const amountOut = await contract.getAmountsOut(tokenToSell, [tokenToSellAddress, BNBTokenAddress, USDTokenAddress]);
    const retVal = new BigNumber(formatEther(amountOut[1])).toNumber();;
    console.log("retVal is " + retVal);
    return retVal;
  } catch (error) { }
}

export async function getBnbPrice() {
  console.debug("GetBNB Price")
  const nodeUrl = getNodeUrl();
  console.debug("nodeUrl is " + nodeUrl);
  const BNBTokenAddress = tokens.wbnb.address //BNB
  const USDTokenAddress = tokens.busd.address //BUSD
  let bnbToSell = parseEther("1.0");
  const contract = getRouterV2Contract();
  const amountOut = await contract.getAmountsOut(bnbToSell, [BNBTokenAddress, USDTokenAddress]);
  return new BigNumber(formatEther(amountOut[1]));
}
