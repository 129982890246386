function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _arrayWithoutHoles(arr) {
    if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}
function _iterableToArray(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _nonIterableSpread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _toConsumableArray(arr) {
    return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}
function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
import { TwitterIcon, TelegramIcon, InstagramIcon, DiscordIcon, CoingeckoIcon, LatokenIcon } from "../Svg";
export var footerLinks = [
    {
        label: "About",
        items: [
            {
                label: "Contact",
                href: "https://docs.pancakeswap.finance/contact-us"
            },
            {
                label: "Blog",
                href: "https://medium.com/pancakeswap"
            },
            {
                label: "Community",
                href: "https://t.me/+_ZM8urn-6FQ4MWU0"
            },
            {
                label: "\u2014"
            },
            {
                label: "Online Store",
                href: " https://www.rucher-apidae.com/",
                isHighlighted: true
            }, 
        ]
    },
    {
        label: "Help",
        items: [
            {
                label: "Customer",
                href: "Support https://docs.pancakeswap.finance/contact-us/customer-support"
            },
            {
                label: "Troubleshooting",
                href: "https://docs.pancakeswap.finance/help/troubleshooting"
            },
            {
                label: "Guides",
                href: "https://docs.pancakeswap.finance/get-started"
            }, 
        ]
    },
    {
        label: "Developers",
        items: [
            {
                label: "Documentation",
                href: "https://docs.pancakeswap.finance"
            }
        ]
    }, 
];
export var socials = [
    {
        label: "Twitter",
        icon: TwitterIcon,
        href: "https://twitter.com/ApidaeToken"
    },
    {
        label: "Telegram",
        icon: TelegramIcon,
        items: [
            {
                label: "English",
                href: "https://t.me/+_ZM8urn-6FQ4MWU0"
            },
            {
                label: "Fran\xe7ais",
                href: "https://t.me/+_ZM8urn-6FQ4MWU0"
            }, 
        ]
    },
    {
        label: "Instagram",
        icon: InstagramIcon,
        href: "https://www.instagram.com/lerucherdapidae/"
    },
    // {
    //   label: "Github",
    //   icon: GithubIcon,
    //   href: "https://github.com/pancakeswap/",
    // },
    {
        label: "Discord",
        icon: DiscordIcon,
        href: "https://discord.gg/PrUttHR9XT"
    },
    {
        label: "Coingecko",
        icon: CoingeckoIcon,
        href: "https://www.coingecko.com/en/coins/apidae"
    },
    {
        label: "Latoken",
        icon: LatokenIcon,
        href: "https://latoken.com/exchange/BAPT_USDT"
    }, 
];
export var langs = _toConsumableArray(Array(20)).map(function(_, i) {
    return {
        code: "en".concat(i),
        language: "English".concat(i),
        locale: "Locale".concat(i)
    };
});
